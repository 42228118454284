import React, {Component} from "react"
import {graphql, Link} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import _shuffle from "lodash.shuffle";
import Highlightable from "../components/highlightable";
import ProductCell from "../components/product/productCell"
import AnimatedLines from "../components/animatedLines"
import ReasonsToSmileSection from "../components/home/reasons-to-smile-section";
import { isBrowser } from "react-device-detect";
import PropTypes from "prop-types";
import ShopCustomerReview from '../components/shop-customer-review';
import "../pages/shop.scss";

class ShopTemplate extends Component {
  constructor(props) {
    super(props)
    const products = props.data.allWcProducts.edges;
    this.state = {
      products: products,
      filter: props.filter === "" ? null : props.filter.toUpperCase(),
      strengthFilter: null,
      filteredProducts: props.filter === "" ? products : products.filter(function(e) {
        let names = e.node.categories.map((x) => {
          let name = x.name
          return name.toUpperCase()
        })
        return names.includes(props.filter.toUpperCase())
      }),
      reviewsDataMap: props.reviewsData
    }
  }

  componentDidMount = () => {
    
  }

  handleCategory = (event, category) => {
    event.preventDefault()
    event.target.blur()
    if (category === this.state.filter) {
        let filtered = this.state.products;
        if(this.state.strengthFilter != null){
            if(this.state.strengthFilter === "1000"){
                filtered = this.state.products.filter(function(e) {
                const strengthProducts = e.node.grouped_products_nodes.find(product => product.slug.indexOf(this.state.strengthFilter) !== -1 )
                return typeof strengthProducts !== "undefined"
                });
            }else{
                if(this.state.strengthFilter === "500"){
                    filtered = this.state.products.filter(function(e) {
                    const strengthProducts = e.node.grouped_products_nodes.find(product => (product.slug.indexOf("500") !== -1 || product.slug.indexOf("1000") !== -1) )
                    return typeof strengthProducts !== "undefined"
                    });
                }
            }
        }
      this.setState({filter: null, filteredProducts: filtered})

    } else {
      this.filterByCategory(category)
      // window.___history.push(`?category=${category}`)
    }

  }

  handleStrength = (event, strength) => {
    event.preventDefault()
    event.target.blur()
    if (strength === this.state.strengthFilter) {

        let filtered = this.state.products;
        if(this.state.filter != null){
            const categoryUppercased = this.state.filter.toUpperCase()
             filtered = this.state.products.filter(function(e) {
            let names = e.node.categories.map((x) => {
                let name = x.name
                return name.toUpperCase()
            })
            return names.includes(categoryUppercased)
            });
        }
      this.setState({strengthFilter: null, filteredProducts: filtered})

    } else {
      this.filterByStrength(strength)
      // window.___history.push(`?category=${category}`)
    }

  }

  filterByCategory = (category) => {
    const categoryUppercased = category.toUpperCase()
    let filtered = this.state.products.filter(function(e) {
      let names = e.node.categories.map((x) => {
        let name = x.name
        return name.toUpperCase()
      })
      return names.includes(categoryUppercased)
    });
    if("UNWIND" === categoryUppercased || "ENERGIZE"  === categoryUppercased){
      if(this.state.strengthFilter != null){
          if(this.state.strengthFilter === "1000"){
              const SFiler = this.state.strengthFilter;
              filtered = filtered.filter(function(e) {
              const strengthProducts = e.node.grouped_products_nodes.find(product => product.slug.indexOf(SFiler) !== -1 )
              return typeof strengthProducts !== "undefined"
              });
          }else{
              if(this.state.strengthFilter === "500"){
                  filtered = filtered.filter(function(e) {
                  const strengthProducts = e.node.grouped_products_nodes.find(product => (product.slug.indexOf("500") !== -1 || product.slug.indexOf("1000") !== -1) )
                  return typeof strengthProducts !== "undefined"
                  });
              }
          }
      }
    }
    
    this.setState({filteredProducts: filtered, filter: categoryUppercased})
  }

  filterByStrength = (strength) => {
    let filtered;
     if(strength === "1000"){
        filtered = this.state.products.filter(function(e) {
        const strengthProducts = e.node.grouped_products_nodes.find(product => product.slug.indexOf(strength) !== -1 )
        return typeof strengthProducts !== "undefined"
        });
    }else{
        if(strength === "500"){
            filtered = this.state.products.filter(function(e) {
            const strengthProducts = e.node.grouped_products_nodes.find(product => (product.slug.indexOf("500") !== -1 || product.slug.indexOf("1000") !== -1) )
            return typeof strengthProducts !== "undefined"
            });
        }
    }
    if(this.state.filter != null){
        const categoryUppercased = this.state.filter.toUpperCase()
         filtered = filtered.filter(function(e) {
        let names = e.node.categories.map((x) => {
            let name = x.name
            return name.toUpperCase()
        })
        return names.includes(categoryUppercased)
        });
    }
    
    this.setState({filteredProducts: filtered, strengthFilter: strength})
  }

  render() {
    let title, desp, metaTitle, metaDesc
    switch (this.state.filter) {
        case "UNWIND":
          title = "Unwind"
          desp = "We start with 100% organic CBD, then add 5-HTP & melatonin to relax your body and ease into a chiller headspace."
          metaTitle = "CBD Oil | Unwind"
          metaDesc = "Relax your body and ease into a chiller headspace with our Unwind CBD oil made with 100% organic hemp extra. Learn more about our CBD product at ReasonToSmile.com."
          break
        case "ENERGIZE":
          title = "Energize"
          desp = "All the benefits of 100% organic CBD, plus vitamin B12 for increased energy, ginseng for mental clarity and 5-HTP for improved mood."
          metaTitle = "CBD Oil | Energize"
          metaDesc = "Experience the benefits of our Energize CBD oil for increased energy, mental clarity and improved mood. Shop our Pure & Potent CBD products with clean ingredients at Reason to Smile."
          break
        case "BALANCE":
          title = "Balance"
          desp = "Our CBD gummies are the first of their kind! Not only are they vegan, they are also packed with 9 essential vitamins and minerals."
          metaTitle = "CBD Gummies | Daily Multivitamins"
          metaDesc = "Try our CBD vegan gummies packed with 9 essential vitamins and minerals! Learn more about our gummies and their benefits at ReasontoSile.com"
          break
        case "REJUVENATE":
          title = "Rejuvenate"
          desp = "All the benefits of 100% organic CBD, plus vitamin B12 for increased energy, ginseng for mental clarity and 5-HTP for improved mood."
          metaTitle = "CBD Cream and Lotion"
          metaDesc = "Shop our age defying CBD face cream and body lotion that leaves your skin supple and smooth. Need pain relief? Try our CBD pain cream! Learn more at Reason to Smile."
          break  
        default:
          title = ""
          desp = ""
          metaTitle = "CBD Products | CBD Oil, Gummies and Cream"
          metaDesc = "Each of our CBD products are 100% CBD with no THC! Shop our CBD oil, CBD gummies, CBD pain cream and learn about the benefits of each ingredient in the product at Reason to Smile today!"
          break
    }
    
    return (

      <Layout>
        <SEO title={metaTitle} description={metaDesc} />
        <div className="shop-section">
        <section className="hero is-medium has-absolute-navbar shop-filter">
        <div className="hero-body" style={{paddingBottom:"0px"}}>
            <div className="container">
              <div className="columns is-centered">
                <div className="column is-6 has-text-centered">
                  <h1>{title}</h1>
                  <p style={{fontSize: '18px', marginBottom: '20px'}}>{desp}</p>
                </div>

              </div>
            </div>
            { ( this.state.filter != null ) ?
                <ShopCustomerReview reviewsDataMap={this.state.reviewsDataMap} filter={this.state.filter} filteredProducts={this.state.filteredProducts}/>
                :
                false
              }

          <div className="container">
            <div className="columns is-centered">
              <div className="column is-12 has-text-centered">

                
                <br/>
                <div className="buttons has-addons is-centered effect-filter">
                  <button className={`button is-warning ${this.state.filter === "UNWIND" ? "is-selected" : 'is-outlined'}`} onClick={(e) => this.handleCategory(e, "UNWIND")}>
                    {this.state.filter === "UNWIND" ?
                        <img src={require(`../images/effects/unwind-thin-white.svg`)} alt=""/>
                        :
                        <img src={require(`../images/effects/unwind-thin-yellow.svg`)} alt=""/>
                    }
                      UNWIND
                  </button>
                  <button className={`button is-warning ${this.state.filter === "ENERGIZE" ? "is-selected" : 'is-outlined'}`} onClick={(e) => this.handleCategory(e, "ENERGIZE")}>
                    {this.state.filter === "ENERGIZE" ?
                        <img src={require(`../images/effects/energize-thin-white.svg`)} alt=""/>
                        :
                        <img src={require(`../images/effects/energize-thin-yellow.svg`)} alt=""/>
                    }    
                      ENERGIZE
                  </button>
                  <button className={`button is-warning ${this.state.filter === "BALANCE" ? "is-selected" : 'is-outlined'}`} onClick={(e) => this.handleCategory(e, "BALANCE")}>
                    {this.state.filter === "BALANCE" ?
                            <img src={require(`../images/effects/balance-thin-white.svg`)} alt=""/>
                            :
                            <img src={require(`../images/effects/balance-thin-yellow.svg`)} alt=""/>
                    }  
                      BALANCE
                  </button>
                  <button className={`button is-warning ${this.state.filter === "REJUVENATE" ? "is-selected" : 'is-outlined'}`} onClick={(e) => this.handleCategory(e, "REJUVENATE")}>
                    {this.state.filter === "REJUVENATE" ?
                            <img src={require(`../images/effects/rejuvenate-thin-white.svg`)} alt=""/>
                            :
                            <img src={require(`../images/effects/rejuvenate-thin-yellow.svg`)} alt=""/>
                    }
                      REJUVENATE
                  </button>
                </div>

              </div>

            </div>
            { ("UNWIND" === this.state.filter || "ENERGIZE"  === this.state.filter)?
            <div className="columns is-centered">
              <div className="column is-12 has-text-centered">

                
                <br/>
                <div className="buttons has-addons is-centered strength-filter">
                  <button style={{display:'block', padding: '10px 30px', lineHeight: '1.3'}} className={`button is-warning ${this.state.strengthFilter === "500" ? "is-selected" : 'is-outlined'}`} onClick={(e) => this.handleStrength(e, "500")}>
                    
                      <span>STANDARD STRENGTH</span>
                      <span style={{display:'block',fontWeight:'normal'}}>500 MG CBD</span>
                  </button>
                  <button style={{display:'block', padding: '10px 30px', lineHeight: '1.3'}} className={`button is-warning ${this.state.strengthFilter === "1000" ? "is-selected" : 'is-outlined'}`} onClick={(e) => this.handleStrength(e, "1000")}>
                       
                    <span>FULL STRENGTH</span>
                    <span style={{display:'block',fontWeight:'normal'}}>1000 MG CBD</span>
                  </button>
                 
                </div>

              </div>

            </div>
            :
            false
            }
          </div>
          </div>

        </section>
        <section className="section">
          <div className="container">
            <div className="columns is-multiline is-centered is-mobile">

              {this.state.filteredProducts.map(({node}) => (
                <ProductCell product={node} key={node.id} strength={this.state.strengthFilter}/>
              ))}
            </div>

          </div>

        </section>
        <section className="section is-medium" style={{
          position: "relative"
        }}>
        
        <AnimatedLines className="lines-01" style={{
          transform: "scaleX(-1) rotate(80deg)",
          position: "absolute",
          top: "50%",
          left: isBrowser ? "-10%" : "-95%",
          zIndex: "10",
          width: "587px"
        }}/>
        <AnimatedLines className="lines-02" style={{
          position: "absolute",
          transform: "scaleX(-1) rotate(-20deg)",
          top: "-80%",
          right: isBrowser ? "-8%" : "-95%",
          zIndex: "-1",
          width: "610px"
        }}/>
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-4-desktop is-6-tablet is-12-mobile has-text-centered">

                <h3 className="has-text-weight-bold">
                  <Highlightable>Need guidance?</Highlightable>
                </h3>
                <h2>Questions? Concerns? <br/>We’re here to help</h2>
                <div className="need-guidance">
                  <p>Don’t hesitate to contact us for whatever reason. <br/>Email us at <b><a href="mailto:hello@reasontosmile.com">hello@reasontosmile.com</a></b> or use our <b><a className="my_custom_intercom" href="mailto:hello@reasontosmile.com">live chat</a></b>. <br/>Our team of dedicated experts is here for you.</p>
                </div>
                <button className="button is-primary my_custom_intercom">ASK US</button>
              </div>

            </div>
          </div>

        </section>
        <div className="shop-reason-to-smile">
        <ReasonsToSmileSection style={{marginTop:'-130px'}}/>
        </div>
        </div>
      </Layout>
    )
  }
}

ShopTemplate.propTypes = {
    data: PropTypes.object.isRequired,
    filter: PropTypes.string,
    reviewsData: PropTypes.object
  };
  
  // export default ProductTemplate;
  
export default ({ data, pageContext }) => (
    <ShopTemplate data={data} filter={pageContext.filter} reviewsData={pageContext.reviewsData} />
)

// Set here the ID of the home page.
export const pageQuery = graphql `
  query {
      allWcProducts(filter: {catalog_visibility: {eq: "visible"}, status: {eq: "publish"}, tags: {elemMatch: {name: {eq: "CBD"}}}}) {
      edges {
        node {
          id
          type
          wordpress_id
          slug
          name
          price
          categories {
            wordpress_id
            name
          }
          acf {
            flavor
            effect
            product_type
          }
          images {
            id
            alt
            src
          }
          grouped_products_nodes {
            id
            wordpress_id
            slug
            type
            price
          }
        }
      }
    }
  }
`