import React, { Component } from "react";
import Slider from "react-slick";
import _shuffle from "lodash.shuffle";
import CustomerReview from "./home/customer-review";
import WatchedElement from "../components/watchedElement";
import "./home/customer-review-section.scss";
class ShopCustomerReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reviews: []
    };
  }
  componentDidMount() {
  }
  render() {
    const reviewSettings = {
      dots: true,
      infinite: true,
      speed: 1000,
      autoplaySpeed: 10000,
      autoplay: true,
      fade: false,
      slidesToShow: 3,
      slidesToScroll: 3,
      className: "has-white",
      responsive: [
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    let reviews = [];
    let avartarIndexs = [1,2,3];
    this.props.filteredProducts.forEach(item => {
        if(this.props.reviewsDataMap.hasOwnProperty(item.node.wordpress_id)){
          if("BALANCE" === this.props.filter.toUpperCase()){
            reviews = this.props.reviewsDataMap[item.node.wordpress_id].reviews;
          }else{
            reviews = reviews.concat(this.props.reviewsDataMap[item.node.wordpress_id].reviews);
          }
        }
      })
      reviews = _shuffle(reviews.filter(r => r.score === 5)).slice(0,3);
    return (
        <div className="container">
          
          {reviews.length > 0 ? (
              <Slider ref={slider => (this.slider = slider)} {...reviewSettings}>
                {reviews.map((review, key) => {
                  var avatarIndex = Math.floor(Math.random() * 3) + 1;
                  if(avartarIndexs.length > 0){
                    var randomIndex = Math.floor(Math.random() * avartarIndexs.length);
                    avatarIndex = avartarIndexs[randomIndex];
                    avartarIndexs.splice(randomIndex, 1);
                  }
                  return <CustomerReview key={key} review={review} avatar={avatarIndex}/>
                  })}
              </Slider>
          ) : (
            false
          )}
        </div>
    );
  }
}

export default ShopCustomerReview;
